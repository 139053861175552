<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-app-bar
      color="primary"
      tile
      dark
      flat
      v-if="!expiredToken && !loadingTemplate"
    >
      <v-app-bar-title> </v-app-bar-title>
      <v-list-item class="px-1">
        <v-list-item-content>
          <v-list-item-subtitle class="white--text">
            Solicitação de alteração de modelo de assinatura
          </v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold">
            <span v-if="$vuetify.breakpoint.smAndUp">Novo modelo:</span>
            {{ templateData.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-app-bar>

    <PageLoading v-if="loadingTemplate" />

    <div
      v-else-if="expiredToken"
      class="d-flex justify-center align-center"
      style="height: 100vh"
    >
      <div class="ma-0 d-flex flex-column justify-center align-center">
        <Logo width="100" height="100" product="conecta_sign" />
        <v-card-title class="text-h5 font-weight-bold mb-6">
          Ops! O link de confirmação solicitado não é mais válido.
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn
            x-large
            color="error"
            class="text-none text-body-1 font-weight-medium px-10"
            @click="openLink('https://mail.google.com/')"
          >
            <v-icon left v-text="'mdi-gmail'" size="25" class="mr-4" />
            {{ $t("common.backToGmail") }}
          </v-btn>
          <v-btn
            dark
            x-large
            color="primarySuite"
            class="text-none text-body-1 font-weight-medium px-10"
            @click="openLink('https://app.conectasuite.com')"
          >
            <Logo
              width="28"
              height="28"
              product="conecta_suite"
              white
              class="mr-4"
            />
            Acessar Conecta Suite
            <v-icon right v-text="'ph-arrow-right'" class="ml-4" />
          </v-btn>
        </v-card-actions>
      </div>
    </div>

    <div v-else>
      <!-- Início do email preview -->
      <v-card-text class="pb-8 pt-0 mt-5">
        <div
          v-if="responseSent"
          class="d-flex flex-column justify-center align-center mb-4"
        >
          <v-icon
            v-text="
              acceptApplication ? 'ph-fill ph-check-circle' : 'ph-x-circle'
            "
            :color="acceptApplication ? 'success' : 'error'"
            size="100"
          />
          <v-card-title class="text-h4">
            {{
              acceptApplication
                ? "Aplicação de assinatura aceita"
                : "Aplicação de assinatura recusada"
            }}
          </v-card-title>
        </div>

        <Alert
          type="info"
          class="mx-4 my-4"
          dense
          :text="!setPreviewDark"
          :dark="setPreviewDark"
        >
          Algumas informações podem estar em branco, se uma TAG utilizada não
          estiver preenchida.
        </Alert>

        <v-toolbar color="grey darken-3" dark dense flat>
          <h4>{{ $t("common.emailSignatureModelPreview") }}</h4>
          <v-spacer />

          <div id="v-step-5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="setPreviewDark = !setPreviewDark"
                  :disabled="responseSent"
                  icon
                >
                  <v-icon
                    v-text="
                      setPreviewDark ? 'ph-fill ph-sun-dim' : 'ph-moon-stars'
                    "
                  />
                </v-btn>
              </template>
              <span
                >Visualizar assinatura no tema
                {{
                  setPreviewDark ? $t("common.light") : $t("common.dark")
                }}</span
              >
            </v-tooltip>
          </div>
        </v-toolbar>

        <v-card
          tile
          flat
          class="pb-4"
          :dark="setPreviewDark"
          :disabled="responseSent"
        >
          <v-card-subtitle class="py-1" v-if="$vuetify.breakpoint.smAndUp">
            Para:
            <span class="px-2" v-text="`exemplo@exemplo.com`"></span>
          </v-card-subtitle>
          <v-divider class="mx-4" />

          <v-card-subtitle class="py-1" v-if="$vuetify.breakpoint.smAndUp">
            Assunto
            <span class="px-2">{{ $t("common.checkTheNewSignature") }}</span>
          </v-card-subtitle>
          <v-divider class="mx-4" />
        </v-card>

        <v-card
          :dark="setPreviewDark"
          :max-height="450"
          :disabled="responseSent"
          min-height="200px"
          flat
          tile
          class="px-4 pb-2 overflow-y-auto fill-height"
        >
          <span
            class="mobile-scale"
            v-if="templateData.html"
            v-html="templateData.html"
          ></span>
        </v-card>

        <v-divider></v-divider>
      </v-card-text>

      <v-footer fixed>
        <v-row
          v-if="!responseSent"
          :class="`ma-0 pa-0 ${$vuetify.breakpoint.lgAndUp ? 'pb-6' : ''}`"
        >
          <v-col
            :order="$vuetify.breakpoint.xs ? 2 : 1"
            cols="12"
            sm="6"
            xs="12"
          >
            <v-btn
              color="error"
              :class="'text-none text-h6 font-weight-medium px-8'"
              elevation="0"
              x-large
              :disabled="disableActions"
              block
              @click="
                showConfirmationDialog = true;
                acceptApplication = false;
              "
            >
              <v-icon v-text="'ph-x-circle'" size="24" left class="mr-4" />
              Rejeitar
            </v-btn>
          </v-col>

          <v-col
            :order="$vuetify.breakpoint.xs ? 1 : 2"
            cols="12"
            sm="6"
            xs="12"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : ''"
          >
            <v-btn
              color="success"
              :class="'text-none text-h6 font-weight-medium px-8'"
              elevation="0"
              :dark="!disableActions"
              :disabled="disableActions"
              x-large
              block
              @click="
                showConfirmationDialog = true;
                acceptApplication = true;
              "
            >
              <v-icon
                v-text="'ph-fill ph-check-circle'"
                size="24"
                left
                class="mr-4"
              />
              Aceitar nova assinatura
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-else
          :class="`ma-0 pa-0 ${$vuetify.breakpoint.lgAndUp ? 'pb-6' : ''}`"
        >
          <v-col
            :order="$vuetify.breakpoint.xs ? 2 : 1"
            cols="12"
            sm="6"
            xs="12"
          >
            <v-btn
              x-large
              block
              color="error"
              class="text-none text-body-1 font-weight-medium px-10"
              @click="openLink('https://mail.google.com/')"
            >
              <v-icon left v-text="'mdi-gmail'" size="25" class="mr-4" />
              {{ $t("common.backToGmail") }}
            </v-btn>
          </v-col>

          <v-col
            :order="$vuetify.breakpoint.xs ? 1 : 2"
            cols="12"
            sm="6"
            xs="12"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : ''"
          >
            <v-btn
              dark
              block
              x-large
              color="primarySuite"
              class="text-none text-body-1 font-weight-medium px-10"
              @click="openLink('https://app.conectasuite.com')"
            >
              <span style="max-width: 28px" class="mr-4">
                <Logo width="28" height="28" product="conecta_suite" white />
              </span>
              Acessar Conecta Suite
              <v-icon right v-text="'ph-arrow-right'" class="ml-4" />
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </div>

    <HeaderDialog
      :show="showConfirmationDialog"
      title="Deseja confirmar a ação?"
      :customButtom="true"
      outlined
      color="primary"
      button-class="white--text"
      dense
      showCancel
      :loading="showLoading"
      :disabledAction="showLoading"
      closeText="Cancelar"
      @close="resetDataValue"
      @action="sentResponse(acceptApplication)"
    >
      <template v-slot:body>
        <span
          >Ao confirmar, esta página não estará mais disponível para
          consulta.</span
        >
      </template>
    </HeaderDialog>

    <Snackbar
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />
  </v-container>
</template>

<script>
import PageLoading from "@/components/PageLoading.vue";
import { openInNewTab } from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";

export default {
  name: "ConfirmApliedTemplate",

  components: {
    PageLoading,
  },

  data() {
    return {
      templateData: false,
      setPreviewDark: false,
      loadingTemplate: false,
      acceptApplication: false,
      showConfirmationDialog: false,
      showLoading: false,
      expiredToken: false,
      showSnackbar: false,
      snackbarText: "",
      snackbarType: "success",
      disableActions: false,
      responseSent: false,
    };
  },

  methods: {
    openInNewTab,

    openLink(link) {
      openInNewTab(link);
      window.top.close();
    },

    snackbar(type, message) {
      this.snackbarType = type;
      this.snackbarText = message;
      this.showSnackbar = true;
    },

    resetDataValue() {
      this.showConfirmationDialog = false;
    },

    async getTemplate() {
      this.loadingTemplate = true;

      const template_key = this.$route.params.id;
      const token = this.$route.query.token;
      let url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/template-preview/${template_key}`;

      await this.$axios
        .get(url, {
          headers: {
            Authorization: token,
          },
        })
        .then(({ data }) => {
          this.templateData = data;
        })
        .catch(() => {
          this.expiredToken = true;
        })
        .finally(() => {
          this.loadingTemplate = false;
          this.acceptApplication = false;
          this.resetDataValue();
        });
    },

    async sentResponse(confirm) {
      this.showLoading = true;
      const token = this.$route.query.token;
      const template_key = this.$route.params.id;

      let url = `${process.env.VUE_APP_API_BASE_URL}/users/confirm-signature`;

      const payload = {
        confirm_aplication: confirm,
        signature: this.templateData.html,
        template_key,
      };

      await this.$axios
        .post(url, payload, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          this.snackbar("success", this.$t("successMessages.templateApplied"));
          this.disableActions = true;
          this.responseSent = true;
        })
        .catch(({ response }) => {
          this.snackbar("error", errorMessages[response.data.code]);
        })
        .finally(() => {
          this.showLoading = false;
          this.resetDataValue();
        });
    },
  },

  async beforeMount() {
    await this.getTemplate();
  },
};
</script>

<style scoped>
@media screen and (max-width: 650px) {
  #sizeOfScreenForButtons {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .mobile-scale {
    zoom: 0.8;
    -moz-transform: scale(0.8);
  }
}
</style>
